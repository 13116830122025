import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const OurApproachPage = () => {
  return (
    <Layout pageTitle="Our Approach">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../media/our_approach_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                objectPosition="bottom"
                layout="fullWidth" />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>COMPANY</h2>
                <h1>Our Approach</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section text-center">
          <Row>
            <Col>
              <h3>WESSUC PROUDLY LEADS THE WAY IN ENVIRONMENTALLY RESPONSIBLE SOLUTIONS, TURNING WASTE INTO VALUE</h3>
              <p>
                Whether you’re a grower, a product-oriented company, or represent a municipality, we have the latest in technology, processes, and methods of reducing and re-using waste. As leaders in our industry, we pride ourselves on both our commitment to safety and our use of innovative new waste solution technology.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>SAFETY</h3>
              <p>
                At Wessuc, we place the safety of our team and those around us above all else. Every job we do, no matter how small, is conducted with safety in mind. From start to finish, we consider what we’re doing and how we’re doing it to ensure that it’s done safely every step of the way. It only takes one safety error to result in an accident. At Wessuc, that’s one accident too many.
              </p>
              <p>
                To ensure that we have the very best and latest safety processes and procedures in place at Wessuc, we are affiliated with the following safety organizations:
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../media/our_approach_safety.jpg"
                alt="employee secured to railing using appropriate fall arrest gear" />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="bg-gray pad section default-text">
          <Row xl={3} lg={3} md={1} sm={1} xs={1}>
            <Col>
              <h3>ISN</h3>
              <p>
                Through the ISN, we have access to a wide variety of documentation and information to help us with workplace and environmental safety compliance. Their programs help us with tracking and logging of illness and injuries as well as hours, incidents, near misses or other site-specific information on a monthly basis. We also benefit from their experts’ reviews and updates on the latest in compliance processes and procedures.
              </p>
            </Col>
            <Col>
              <h3>IHSA</h3>
              <p>
                IHSA is a leader in health and safety education and an integral part of Ontario’s health and safety system with recognition by the Ministry of Labour, the Ministry of Transportation, the Ministry of Training, Colleges and Universities, and the Workplace Safety and Insurance Board as designated trainers and consultants.
              </p>
            </Col>
            <Col>
              <h3>COR</h3>
              <p>
                Wessuc has obtained its Certificate of Recognition (COR™) through IHSA, a distinguished and elite designation in health and safety compliance. COR™ is aimed at driving positive workplace behavior and practices that lead to improved safety performance. There are 19 elements of the COR™ audit tool including hazard analysis, safe work practices, preventative maintenance, emergency preparedness, and workplace inspections.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>TECHNOLOGY</h3>
              <p>
                We’re not one to sit back and keep doing things the same old way just because new ways don’t exist. Instead, we invent them ourselves. Here are a few examples of how we continue to lead the way in waste solution technology.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../media/our_approach_technology.jpg"
                alt="green tractor spreads biosolids in an open field" />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>MOBILE SCREENING SERVICE</h3>
              <p>
                Our mobile screening units have a variety of screening capabilities that can be used to remove all physical impurities during the cleaning process. This process ensures that large hair balls, rags, plastics and even grit are separated and removed. It ensures that material going to land or returning to the plant is clean and environmentally responsible.
              </p>
              <p>
                This mobile screening unit is completely self-powered, pumps 600 to 800 gallons per minute, and results in ¼ inch debris separation and compaction for efficient disposal.
              </p>
              <p>
                Depending on how fine of a separation you require, we have other screening and solids removal options available, removing solids as small as .5 micron in size if required.
              </p>
            </Col>
            <Col>
              <h3>EXCLUSIVE ROTARY DRUM DEWATERING TECHNOLOGY</h3>
              <p>
                Wessuc’s revolutionary rotary drum cleans large tanks in half the time of traditional dewatering units.  Its unique construction eliminates material bypassing the system ensuring all plastics, rags, grit and hair balls and other solids that build up in tanks are captured. Sludge is mixed with a polymer and pumped into the drum where it separates the solids and liquid. The water permeates from the dewatering unit through the small slits in the drum resulting in effective dewatering and volume reduction of the contained material. After the final cycle of filling, the retained materials will continue to consolidate by desiccation which is aided by the tumbling of the material as the drum rotates. This process can continue until the desired dryness is achieved.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={3} lg={3} md={1} sm={1} xs={1}>
            <Col>
              <h3>DUAL VAC SYSTEM</h3>
              <p>
                Larger projects often require additional time to complete. Through the use of our custom designed, dual vacuum system, we can maintain continuous suction from start to finish of a project. Downtime is reduced by 50 per cent allowing this unique Wessuc system to take half the time to clean a large tank.
              </p>
            </Col>
            <Col>
              <h3>PUMPING UNITS</h3>
              <p>
                Our pumper units can be operated from 200 meters to 1.5 km in distance to the tractor. With a storage tank capacity of 43 cubic meters and a capability to pump between 900 and 1000 imperial gallons per minute, they can feed our tractors with a steady flow of material. At the same time, Wessuc tractors use our own variable tractor speed system that reacts to any changes coming in through the hose. This ensures consistent material delivery to the fields.
              </p>
            </Col>
            <Col>
              <h3>OVERHEAD LOADING PIPES</h3>
              <p>
                By custom building mobile units for top loading, Wessuc reduces loading times and eliminates the need to climb on trailers, which improves overall safety and decreases the chance of spillage. The units have been used at lagoon sites, pumping stations, and on road sides during spills response.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>REDUCED FUEL CONSUMPTION</h3>
              <p>
                To reduce our fuel consumption, increase our horsepower and torque output, and reduce emissions, Wessuc trucks use a system that adds hydrogen into the engines’ fuel source. By providing a more complete burn, it also results in increased engine life, decreased engine maintenance and pollution control maintenance, and lower residual hydrocarbons in the engine or in the exhaust.
              </p>
            </Col>
            <Col>
              <h3>THE WORLD'S FIRST FLOW-CONTROLLED DRAGLINE SYSTEM</h3>
              <p>
                We couldn’t accept that variable rate application was accomplished by restricting the flow through the applicator. It was a horribly inefficient method especially for large volumes. Instead, we developed a unique way of controlling the tractor speed rather than limiting the flow rate. Our method sends flow meter data to a GPS unit which adjusts the speed of the tractor to achieve the required flow and application rates. This information can be passed on to the grower to provide yield monitoring and nutrient application comparisons.
              </p>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default OurApproachPage